// Gatsby Browser API

// Webfont css delay.
//
// When the browser load the page, this `onClientEntry` set the stylesheet rel
// attribute to "active" the webfonts css.
// This way when the browser load the html page, webfonst doesn't block the
// render.

const onClientEntry = () => {
	// webfonts
	//	const griddoWebfonts = document.getElementById("griddo-webfonts");
	//	if (griddoWebfonts) {
	//		griddoWebfonts.setAttribute("rel", "stylesheet");
	//	}

	// create cookies depending of url get properties
	const cookiesToInclude = [
		"utm_source",
		"utm_id",
		"utm_medium",
		"utm_term",
		"utm_content",
		"utm_campaign",
		"gad",
		"gclid",
		"utm_campaignid",
	];
	const urlObj = new URL(window.location.href);
	for (const [key, value] of urlObj.searchParams.entries()) {
		if (cookiesToInclude.includes(key)) {
			const date = new Date();
			date.setDate(date.getDate() + 7);
			const cookieData = `${key}=${value};expires=${date.toUTCString()};path=/`;
			document.cookie = cookieData;
		}
	}
};

export default {
	onClientEntry,
};
