//
// Griddo Modules
//
// Every Module and their types must be imported and reexported.
// Yes, a barrel index :)

import { HighlightCarouselProps, ImageBannerProps } from "@autoTypes";
import { Component } from "@griddo/core";
import { QuoteTestimonialCollectionProps } from "@ui/modules/QuoteTestimonialCollection";
import * as React from "react";

// Types
import type { AccordionCollectionProps } from "./AccordionCollection";
import type { AccreditationsProps } from "./Accreditations";
import type { BasicContentProps } from "./BasicContent";
import type { BasicHeroProps } from "./BasicHero";
import type { BasicTextProps } from "./BasicText";
import type { CardCollectionProps } from "./CardCollection";
import type { ChartCollectionProps } from "./ChartCollection";
import type { CypherCollectionProps } from "./CypherCollection";
import type { DownloadCollectionProps } from "./DownloadCollection";
import type { EventDistributorProps } from "./EventDistributor";
import type { FeaturedAudiovisualProps } from "./FeaturedAudiovisual";
import type { FeaturedBlockProps } from "./FeaturedBlock";
import type { FeaturedContentProps } from "./FeaturedContent";
import type { FilesProps } from "./Files";
import type { FooterProps } from "./Footer";
import type { FullImageWithTextProps } from "./FullImageWithText";
import type { FullVideoProps } from "./FullVideo";
import type { HeaderProps } from "./Header";
import type { HeroCarouselProps } from "./HeroCarousel";
import type { HeroLandingProps } from "./HeroLanding";
import type { HeroProgramProps } from "./HeroProgram";
import type { HighlightQuoteProps } from "./HighlightQuote";
import type { InnerBasicContentProps } from "./InnerBasicContent";
import type { InnerBasicTextProps } from "./InnerBasicText";
import type { InnerModalLinkCollectionProps } from "./InnerModalLinkCollection";
import type { InnerWysiwygProps } from "./InnerWysiwyg";
import type { IntroFormProps } from "./IntroForm";
import type { LandingIntroProps } from "./LandingIntro";
import type { LandingProgramNavigationProps } from "./LandingProgramNavigation";
import type { LocationMapProps } from "./LocationMap";
import type { LogoCollectionProps } from "./LogoCollection";
import type { ModalLinkCollectionProps } from "./ModalLinkCollection";
import type { NewsHighlightDistributorProps } from "./NewsHighlightDistributor";
import type { NewsSimpleDistributorProps } from "./NewsSimpleDistributor";
import type { OdsCollectionProps } from "./OdsCollection";
import type { OrderedCollectionProps } from "./OrderedCollection";
import type { PeopleDistributorProps } from "./PeopleDistributor";
import type { ProgramListTabsProps } from "./ProgramListTabs";
import type { ProgramNavigationProps } from "./ProgramNavigation";
import type { ProgramQuickFactsProps } from "./ProgramQuickFacts";
import type { ProgramsDistributorProps } from "./ProgramsDistributor";
import type { ResearchProjectsDistributorProps } from "./ResearchProjectsDistributor";
import type { SeminarDistributorProps } from "./SeminarDistributor";
import type { SocialShareProps } from "./SocialShare";
import type { TabsModulesProps } from "./TabsModules";
import type { VideoCardCollectionProps } from "./VideoCardCollection";
import { WeeklyAgendaProps } from "./WeeklyAgenda";
import type { WysiwygProps } from "./Wysiwyg";
// Modules
const AccordionCollection = React.lazy(() => import("./AccordionCollection"));
const Accreditations = React.lazy(() => import("./Accreditations"));
const BasicContent = React.lazy(() => import("./BasicContent"));
const BasicHero = React.lazy(() => import("./BasicHero"));
const BasicText = React.lazy(() => import("./BasicText"));
const CardCollection = React.lazy(() => import("./CardCollection"));
const ChartCollection = React.lazy(() => import("./ChartCollection"));
const CypherCollection = React.lazy(() => import("./CypherCollection"));
const DownloadCollection = React.lazy(() => import("./DownloadCollection"));
const EventDistributor = React.lazy(() => import("./EventDistributor"));
const FeaturedAudiovisual = React.lazy(() => import("./FeaturedAudiovisual"));
const FeaturedBlock = React.lazy(() => import("./FeaturedBlock"));
const FeaturedContent = React.lazy(() => import("./FeaturedContent"));
const Files = React.lazy(() => import("./Files"));
const Footer = React.lazy(() => import("./Footer"));
const FullImageWithText = React.lazy(() => import("./FullImageWithText"));
const FullVideo = React.lazy(() => import("./FullVideo"));
const Header = React.lazy(() => import("./Header"));
const HeroCarousel = React.lazy(() => import("./HeroCarousel"));
const HeroLanding = React.lazy(() => import("./HeroLanding"));
const HeroProgram = React.lazy(() => import("./HeroProgram"));
const HighlightQuote = React.lazy(() => import("./HighlightQuote"));
const HighlightCarousel = React.lazy(() => import("./HighlightCarousel"));
const ImageBanner = React.lazy(() => import("./ImageBanner"));
const InnerBasicContent = React.lazy(() => import("./InnerBasicContent"));
const InnerBasicText = React.lazy(() => import("./InnerBasicText"));
const InnerModalLinkCollection = React.lazy(
	() => import("./InnerModalLinkCollection")
);
const InnerWysiwyg = React.lazy(() => import("./InnerWysiwyg"));
const IntroForm = React.lazy(() => import("./IntroForm"));

const LandingIntro = React.lazy(() => import("./LandingIntro"));
const LandingProgramNavigation = React.lazy(
	() => import("./LandingProgramNavigation")
);
const LocationMap = React.lazy(() => import("./LocationMap"));
const LogoCollection = React.lazy(() => import("./LogoCollection"));
const ModalLinkCollection = React.lazy(() => import("./ModalLinkCollection"));
const NewsHighlightDistributor = React.lazy(
	() => import("./NewsHighlightDistributor")
);
const NewsSimpleDistributor = React.lazy(
	() => import("./NewsSimpleDistributor")
);

const OdsCollection = React.lazy(() => import("./OdsCollection"));
const OrderedCollection = React.lazy(() => import("./OrderedCollection"));
const PeopleDistributor = React.lazy(() => import("./PeopleDistributor"));
const ProgramListTabs = React.lazy(() => import("./ProgramListTabs"));
const ProgramNavigation = React.lazy(() => import("./ProgramNavigation"));
const ProgramQuickFacts = React.lazy(() => import("./ProgramQuickFacts"));
const ProgramsDistributor = React.lazy(() => import("./ProgramsDistributor"));
const QuoteTestimonialCollection = React.lazy(
	() => import("./QuoteTestimonialCollection")
);
const QuoteTestimonial = React.lazy(
	() => import("../modules/QuoteTestimonial")
);
const ResearchProjectsDistributor = React.lazy(
	() => import("./ResearchProjectsDistributor")
);

const SeminarDistributor = React.lazy(() => import("./SeminarDistributor"));
const SocialShare = React.lazy(() => import("./SocialShare"));
const TabsModules = React.lazy(() => import("./TabsModules"));
const VideoCardCollection = React.lazy(() => import("./VideoCardCollection"));
const WeeklyAgenda = React.lazy(() => import("./WeeklyAgenda"));
const Wysiwyg = React.lazy(() => import("./Wysiwyg"));

// Modules for <GriddoModule>
const modules = {
	AccordionCollection,
	Accreditations,
	BasicContent,
	BasicHero,
	BasicText,
	CardCollection,
	ChartCollection,
	CypherCollection,
	DownloadCollection,
	EventDistributor,
	FeaturedAudiovisual,
	FeaturedBlock,
	FeaturedContent,
	Files,
	Footer,
	FullImageWithText,
	FullVideo,
	Header,
	HeroCarousel,
	HeroLanding,
	HeroProgram,
	HighlightQuote,
	HighlightCarousel,
	ImageBanner,
	InnerBasicContent,
	InnerBasicText,
	InnerModalLinkCollection,
	InnerWysiwyg,
	IntroForm,
	LandingIntro,
	LandingProgramNavigation,
	LocationMap,
	LogoCollection,
	ModalLinkCollection,
	NewsHighlightDistributor,
	NewsSimpleDistributor,
	OdsCollection,
	OrderedCollection,
	PeopleDistributor,
	ProgramListTabs,
	ProgramNavigation,
	ProgramQuickFacts,
	ProgramsDistributor,
	QuoteTestimonial,
	QuoteTestimonialCollection,
	ResearchProjectsDistributor,
	SeminarDistributor,
	SocialShare,
	TabsModules,
	VideoCardCollection,
	WeeklyAgenda,
	Wysiwyg,
};

// Types for <GriddoModule>
type ModulesProps =
	| AccordionCollectionProps
	| AccreditationsProps
	| BasicContentProps
	| BasicHeroProps
	| BasicTextProps
	| CardCollectionProps
	| ChartCollectionProps
	| CypherCollectionProps
	| DownloadCollectionProps
	| EventDistributorProps
	| FeaturedAudiovisualProps
	| FeaturedBlockProps
	| FeaturedContentProps
	| FilesProps
	| FooterProps
	| FullImageWithTextProps
	| FullVideoProps
	| HeaderProps
	| HeroCarouselProps
	| HeroLandingProps
	| HeroProgramProps
	| HighlightQuoteProps
	| HighlightCarouselProps
	| ImageBannerProps
	| InnerBasicContentProps
	| InnerBasicTextProps
	| InnerModalLinkCollectionProps
	| InnerWysiwygProps
	| IntroFormProps
	| LandingIntroProps
	| LandingProgramNavigationProps
	| LocationMapProps
	| LogoCollectionProps
	| ModalLinkCollectionProps
	| NewsHighlightDistributorProps
	| NewsSimpleDistributorProps
	| OdsCollectionProps
	| OrderedCollectionProps
	| QuoteTestimonialCollectionProps
	| PeopleDistributorProps
	| ProgramListTabsProps
	| ProgramNavigationProps
	| ProgramQuickFactsProps
	| ProgramsDistributorProps
	| ResearchProjectsDistributorProps
	| SeminarDistributorProps
	| SocialShareProps
	| TabsModulesProps
	| VideoCardCollectionProps
	| WeeklyAgendaProps
	| WysiwygProps;

// Module named export
export {
	AccordionCollection,
	Accreditations,
	BasicContent,
	BasicHero,
	BasicText,
	CardCollection,
	ChartCollection,
	CypherCollection,
	DownloadCollection,
	EventDistributor,
	FeaturedAudiovisual,
	FeaturedBlock,
	FeaturedContent,
	Footer,
	FullImageWithText,
	FullVideo,
	Header,
	HeroCarousel,
	HeroLanding,
	HeroProgram,
	HighlightQuote,
	ImageBanner,
	InnerBasicContent,
	InnerBasicText,
	InnerModalLinkCollection,
	InnerWysiwyg,
	IntroForm,
	LandingIntro,
	LandingProgramNavigation,
	LocationMap,
	LogoCollection,
	ModalLinkCollection,
	NewsHighlightDistributor,
	NewsSimpleDistributor,
	OdsCollection,
	OrderedCollection,
	PeopleDistributor,
	ProgramListTabs,
	ProgramNavigation,
	ProgramQuickFacts,
	ProgramsDistributor,
	SeminarDistributor,
	QuoteTestimonialCollection,
	ResearchProjectsDistributor,
	SocialShare,
	TabsModules,
	VideoCardCollection,
	WeeklyAgenda,
	Wysiwyg,
};

// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// ---------------------------- You shall not pass! ----------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// ---------------------------------------------------------------- Gandalf ----
// -----------------------------------------------------------------------------

// Griddo bundle export
export default modules;

/**
 * `<GriddoModule>`
 *
 * Render modules with their Types dinamically and wrapped them with Griddo
 * functionalities.
 *
 * @example
 * <GriddoModule component="BasicModule" {...basicModuleProps} />
 *
 * Check out the [documentation](https://griddoio.notion.site/GriddoComponent-y-GriddoModule-en-la-instancia-bad16303bb4b49e0b696b62d1f6c40e9)
 */
export const GriddoModule = (props: ModulesProps) => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	if (props?.component === "Header") {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		return <Component libComponents={modules} {...props} />;
	}

	return <Component libComponents={modules} {...props} />;
};
