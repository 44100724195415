import { WeeklyAgendaProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	detail,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<WeeklyAgendaProps> = {
	schemaType: "module",
	component: "WeeklyAgenda",
	category: "distributors",
	displayName: "Weekly Agenda",

	configTabs: [
		{
			title: "content",
			fields: [{ ...heading, mandatory: false }, { ...detail }],
		},

		{
			title: "config",
			fields: [{ ...anchorID }, { ...themeSelector }, { ...verticalSpacing }],
		},
	],

	default: {
		component: "WeeklyAgenda",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		detail: "Lorem ipsum paragraph",
		anchorID: null,
		verticalSpacing: "medium",
		subtheme: "default",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/WeeklyAgenda/thumbnail@1x.png",
		"2x": "/thumbnails/modules/WeeklyAgenda/thumbnail@2x.png",
	},
};

export default schema;
